<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <base-material-card
        v-if="resetCode === null"
        color="red"
        light
        max-width="100%"
        width="400"
        class="px-5 py-3"
      >
        <template #heading>
          <div class="text-center">
            <h1 class="display-2 font-weight-bold mb-2">
              Passwort zurücksetzen
            </h1>
          </div>
        </template>

        <v-card-text class="text-center">
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="email"
                color="red"
                label="E-Mail..."
                prepend-icon="mdi-email"
                name="email"
                @keydown.enter="reset"
              />
            </v-col>

            <v-col
              cols="12"
            >
              <v-btn
                class="mb-5"
                color="red"
                tile
                @click="reset"
                width="100%"
              >
                <v-icon class="mr-4">
                  fa-sign-in-alt
                </v-icon>
                Passwort zurücksetzen
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </base-material-card>
      <base-material-card
        v-else
        color="red"
        light
        max-width="100%"
        width="400"
        class="px-5 py-3"
      >
        <template #heading>
          <div class="text-center">
            <h1 class="display-2 font-weight-bold mb-2">
              Passwort zurücksetzen
            </h1>
          </div>
        </template>

        <v-card-text class="text-center">
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="email"
                color="red"
                label="E-Mail-Adresse"
                prepend-icon="far fa-envelope"
                name="email"
                @keydown.enter="reset"
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="resetCode"
                label="Code"
                prepend-icon="far fa-key-skeleton"
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="newPassword"
                type="password"
                label="neues Passwort"
                prepend-icon="far fa-key"
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="newPassword2"
                type="password"
                label="Passwort wiederholen"
                prepend-icon="far fa-key"
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-btn
                class="mb-5"
                color="red"
                tile
                block
                @click="reset"
              >
                <v-icon class="mr-4">
                  fa-sign-in-alt
                </v-icon>
                Passwort zurücksetzen
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </base-material-card>
    </v-row>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'PagesLogin',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    code: {
      type: String,
      required: false,
      default: null
    },
    email64: {
      type: String,
      required: false,
      default: null
    }
  },

  data: () => ({
    valid: true,
    email: '',
    resetCode: null,
    newPassword: null,
    newPassword2: null
  }),

  methods: {
    reset () {
      const err = []
      const EMAIL_ADDRESS_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

      if (!this.email) err.push('E-Mail-Adresse fehlt!')
      if (this.email && !EMAIL_ADDRESS_REGEX.test(this.email)) err.push('E-Mail-Adresse ungültig!')
      if (this.resetCode && !this.newPassword) err.push('Passwort fehlt!')
      if (this.newPassword !== this.newPassword2) err.push('Passwörter stimmen nicht überein!')

      if (err.length !== 0) {
        this.$store.commit('OPEN_SNACKBAR', `<ul><li>${err.join('</li><li>')}</li></ul>`)
        return
      }

      this.$apollo.mutate({
        mutation: gql`mutation($email: EmailAddress!, $resetCode: String, $newPassword: String) {
            PersonPasswordReset(email: $email, resetCode: $resetCode, newPassword: $newPassword)
          }`,
        variables: {
          email: this.email,
          resetCode: this.resetCode || null,
          newPassword: this.newPassword || null
        }
      }).then(ret => {
        this.resetCode = ''
        if (this.resetCode !== null && this.newPassword !== null && ret?.data?.PersonPasswordReset === true) {
          this.$router.replace('/login')
        }
      }).catch(e => {
        this.$store.commit('OPEN_SNACKBAR', e)
      })
    }
  },

  mounted () {
    if (this.code) this.resetCode = this.code
    if (this.email64) this.email = atob(this.email64)
  }
}
</script>
